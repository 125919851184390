// Generated by Framer (6124c77)

import { addFonts, addPropertyControls, ComponentViewportProvider, ControlType, cx, CycleVariantState, getFonts, RichText, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { Icon as Phosphor } from "https://framerusercontent.com/modules/tYScH7LTqUtz5KUaUAYP/CAjjxbTJBxHwH1MagCef/Phosphor.js";
const PhosphorFonts = getFonts(Phosphor);

const cycleOrder = ["M1kxOxi6K"];

const serializationHash = "framer-SvylL"

const variantClassNames = {M1kxOxi6K: "framer-v-k8ay7z"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, tap, width, ...props}) => { return {...props, pj6_FjPMN: tap ?? props.pj6_FjPMN} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;tap?: any; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, pj6_FjPMN, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "M1kxOxi6K", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onClick1wbdowv = activeVariantCallback(async (...args) => {
if (pj6_FjPMN) {
const res = await pj6_FjPMN(...args);
if (res === false) return false;
}
})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} className={cx(serializationHash, ...sharedStyleClassNames, "framer-k8ay7z", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"M1kxOxi6K"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{backgroundColor: "rgb(255, 255, 255)", borderBottomLeftRadius: 10, borderBottomRightRadius: 10, borderTopLeftRadius: 10, borderTopRightRadius: 10, boxShadow: "0px 10px 20px 0px rgba(0,0,0,0.05)", ...style}}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--framer-font-size": "30px", "--framer-text-color": "var(--extracted-r6o4lv, var(--token-39f1e7bd-d6a2-4d0f-90ef-8d65a8c77a7c, rgb(3, 137, 255)))"}}>test</motion.p></React.Fragment>} className={"framer-mjme9x"} fonts={["Inter"]} layoutDependency={layoutDependency} layoutId={"anl9LuBmI"} style={{"--extracted-r6o4lv": "var(--token-39f1e7bd-d6a2-4d0f-90ef-8d65a8c77a7c, rgb(3, 137, 255))", "--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline"}} verticalAlignment={"top"} withExternalLayout/><ComponentViewportProvider ><motion.div className={"framer-1fh3cf7-container"} layoutDependency={layoutDependency} layoutId={"aTQ8EDOuL-container"}><Phosphor color={"var(--token-963ba7ff-1506-4ab8-a9bc-332b05614e66, rgb(9, 9, 11))"} height={"100%"} iconSearch={"X"} iconSelection={"House"} id={"aTQ8EDOuL"} layoutId={"aTQ8EDOuL"} mirrored={false} onClick={onClick1wbdowv} selectByList={false} style={{height: "100%", width: "100%"}} weight={"bold"} width={"100%"}/></motion.div></ComponentViewportProvider></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-SvylL.framer-2gg16f, .framer-SvylL .framer-2gg16f { display: block; }", ".framer-SvylL.framer-k8ay7z { align-content: center; align-items: center; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 10px; height: 280px; justify-content: center; overflow: hidden; padding: 0px; position: relative; width: 333px; will-change: var(--framer-will-change-override, transform); }", ".framer-SvylL .framer-mjme9x { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", ".framer-SvylL .framer-1fh3cf7-container { flex: none; height: 55px; position: absolute; right: 15px; top: 14px; width: 124px; z-index: 1; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-SvylL.framer-k8ay7z { gap: 0px; } .framer-SvylL.framer-k8ay7z > * { margin: 0px; margin-bottom: calc(10px / 2); margin-top: calc(10px / 2); } .framer-SvylL.framer-k8ay7z > :first-child { margin-top: 0px; } .framer-SvylL.framer-k8ay7z > :last-child { margin-bottom: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 280
 * @framerIntrinsicWidth 333
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"pj6_FjPMN":"tap"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FrameruOvbwd7T9: React.ComponentType<Props> = withCSS(Component, css, "framer-SvylL") as typeof Component;
export default FrameruOvbwd7T9;

FrameruOvbwd7T9.displayName = "Popup";

FrameruOvbwd7T9.defaultProps = {height: 280, width: 333};

addPropertyControls(FrameruOvbwd7T9, {pj6_FjPMN: {title: "Tap", type: ControlType.EventHandler}} as any)

addFonts(FrameruOvbwd7T9, [{explicitInter: true, fonts: [{family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F", url: "https://app.framerstatic.com/Inter-Regular.cyrillic-ext-CFTLRB35.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116", url: "https://app.framerstatic.com/Inter-Regular.cyrillic-KKLZBALH.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+1F00-1FFF", url: "https://app.framerstatic.com/Inter-Regular.greek-ext-ULEBLIFV.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0370-03FF", url: "https://app.framerstatic.com/Inter-Regular.greek-IRHSNFQB.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF", url: "https://app.framerstatic.com/Inter-Regular.latin-ext-VZDUGU3Q.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD", url: "https://app.framerstatic.com/Inter-Regular.latin-JLQMKCHE.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB", url: "https://app.framerstatic.com/Inter-Regular.vietnamese-QK7VSWXK.woff2", weight: "400"}]}, ...PhosphorFonts], {supportsExplicitInterCodegen: true})